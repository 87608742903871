.site-layout {
    display: flex;
    min-height: 100vh;

    .content {
        padding: 36px 24px;
    }

    footer {
        background-color: #fff;
        text-align: center;

        a {
            display: inline-block;
            margin: 0px 4px;
        }
    }
}