@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ql-editor {
  min-height: 300px !important;
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
}

.ant-card-bordered {
  border-radius: 12px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.ant-layout-content {
  background-color: #fafafa;
}
.ant-layout-footer {
  background-color: #fafafa;
}
.ant-btn {
  border-radius: 5px;
}
.ant-input {
  border-radius: 5px;
}
.ant-table-cell {
  background-color: white !important;
}
.ant-input-search-button {
  border-radius: 0px 5px 5px 0px !important;
}
.ant-dropdown-trigger {
  width: 160px;
}
.ant-input-number-affix-wrapper {
  width: 40%;
}
.ant-form-item-has-error textarea {
  border-color: #ff4d4f;
}
